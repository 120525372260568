import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default {
	init() {
		console.log('home-js loaded');

		// Div qui apparaissent au scroll
		window.addEventListener("scroll", function() {
			var secteur = document.getElementById("secteur-lign");
			var strategie = document.getElementById("logo_strategie"); var carte_france = document.getElementById("img-carte");

			if (window.scrollY > 500) {
				strategie.className = "logo__strategie show"
			}
			if (window.scrollY > 1300) {
				secteur.className = "div__lign-secteur show"
			}
			if (window.scrollY > 4200) {
				carte_france.className = "carte__france show lazy"
			}
			
		});

	}
};
