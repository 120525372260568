import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import lazyLoadInit from '../lazyload-init';
lazyLoadInit();

import { tns } from "tiny-slider/src/tiny-slider";
import 'slick-carousel';


export default {
	init() {
		console.log('single-js loaded');

		// Slider screenshot projet
		const slider = document.querySelector('.screenshot__slider')
		if (slider) {
			tns({
				container: '.screenshot__slider',
				items: 1,
				mouseDrag: true,
				nav: true,
				navPosition: "bottom",
				slideBy: 1,
				speed: 600,
				controls: true,   
				controlsContainer: "#customize-controls",
				autoHeight: true,
				//fixedWidth: 1200,
				//center: false,
			})

		}
	
		// Slider more projet
		const slider2 = document.querySelector('.slider-projets')
		if (slider2) {
			tns({
				container: '.slider-projets',
				items: 4.3,
				gutter: 40,
				slideBy: 4,
				speed: 2500,
				infinite: false,
				nav: false,
				controls: true,
				startIndex: 0,
				controlsContainer: ".projets__arrows",
				autoHeight: true,
				responsive: {
					300: {
						items: 1,
						gutter: 20,
					},
					640: {
						items: 1.4,
						slideBy: 1,
						gutter: 20,
					},
					880: {
						items: 2,
						slideBy: 1,
						gutter: 20,
					},
					1000: {
						items: 2.2,
						slideBy: 3,
						gutter: 20,
					},
					1050: {
						items: 2.4,
						slideBy: 3,
						gutter: 20,
					},
					1400: {
						items: 3.2,
						slideBy: 4,
						gutter: 40,
					},
					1580: {
						items: 3.4,
						slideBy: 4,
						gutter: 40,
					},
					1800: {
						items: 4.2,
						slideBy: 4,
						gutter: 40,
					}
				}
			})
		}

	
	}	
}