import '../utils/fancybox';

export default {
	init() {
		console.log('page-js loaded');

		// liste dépliable
		jQuery(".liste-depliable__title").on("click", function () {
			jQuery(this).toggleClass("active");
			jQuery(this).next(".liste-depliable__content").slideToggle();
		});
	
		// galerie image
		jQuery('[data-fancybox="galerie"]').fancybox({
			thumbs: {
				autoStart: true,
			},
		});
	
	}
};
