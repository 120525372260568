
// Lightbox
import '../utils/fancybox';
import { Tabs } from "../utils/tabs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

import { initMap, initMapSingle, Map, layerGroup } from "../utils/map";

export default {
	init() {
		console.log('common-js loaded');

		// carto
		if(document.querySelector('.map') !== null){
			initMap();
		}

		if(document.querySelector('.map-single') !== null){
			initMapSingle();
		}

		// clients tabs
		var tabs_container = document.getElementById("tabs");
		if (tabs_container !== null) {
			const tabs = new Tabs({
				elem: "tabs",
				open: 0,
			});
		}
	
		// Accordions
		if (document.querySelector('.accordion-container') !== null) {
			new Accordion('.accordion-container');
		}

		// Galery
		$('[data-fancybox="galerie"]').fancybox({
			thumbs: {
				autoStart: true
			}
		});

		// menu visible
		var element = document.getElementById("menu-button");
		element.addEventListener('click', function(){
			var menu = document.getElementById("header-menu");
			menu.classList.toggle("visible");
		
		});

		// menu across 
		var across = document.getElementById("menu__across");
		across.addEventListener('click', function(){
			var menu = document.getElementById("header-menu");
			menu.classList.toggle("visible");
		});

		// menu background image
		$('.menu-item-1').hover(function(){
			$(".bg-1").fadeIn();
		}, function() {
				$(".bg-1").fadeOut();
		});
		$('.menu-item-2').hover(function(){
				$(".bg-2").fadeIn();
		}, function() {
				$(".bg-2").fadeOut();
		});
		$('.menu-item-3').hover(function(){
			$(".bg-3").fadeIn();
		}, function() {
				$(".bg-3").fadeOut();
		});
		$('.menu-item-4').hover(function(){
			$(".bg-4").fadeIn();
		}, function() {
				$(".bg-4").fadeOut();
		});
		$('.menu-item-5').hover(function(){
			$(".bg-5").fadeIn();
		}, function() {
			$(".bg-5").fadeOut();
		});
		$('.menu-item-6').hover(function(){
			$(".bg-6").fadeIn();
		}, function() {
			$(".bg-6").fadeOut();
		});


		// Titres effet
		const titles = document.querySelectorAll(".title__effect");
		if (titles) {
			for (let i = 0; i < titles.length; i++) {
				const el = titles[i];
	
				const tlTitles = gsap.timeline({
					scrollTrigger: {
						trigger: el,
						start: "top 80%",
					},
				});
				tlTitles.from(el, {
					alpha: 0,
					x: 90,
					ease: "power3.out",
					duration: 1.2,
					delay: i === 0 ? 0.4 : 0,
				});
			}
		}

		// info-side effet
		const info_side = document.querySelectorAll(".info-side");
		if (info_side) {
			for (let i = 0; i < info_side.length; i++) {
				const el = info_side[i];
	
				const tlinfo_side = gsap.timeline({
					scrollTrigger: {
						trigger: el,
						start: "top 50%",
					},
				});
				tlinfo_side.from(el, {
					alpha: 0,
					x: 90,
					ease: "power3.out",
					duration: 2,
					delay: i === 0 ? 0.4 : 0,
				});
			}
		}

		
		// ScrollToTop button
		const body = document.querySelector("body");
		if (document.querySelector(".scrolltop")) {
			const tlScrollTop = gsap.timeline({
				scrollTrigger: {
					trigger: body,
					start: "top -200px",
				},
			});
			tlScrollTop.from(document.querySelector(".scrolltop"), {
				alpha: 0,
				y: 40,
				duration: 0.6,
				ease: "power2.out",
			});
		}
	
		document.querySelectorAll(".js-scrollToTop").forEach((item) => {
			item.addEventListener("click", function (e) {
				e.preventDefault();
				gsap.to(window, {
					scrollTo: 0,
					duration: 1.4,
					ease: "power3.inOut",
				});
			});
		});

		// ScrollToDown chevron
		if (document.querySelector(".chevron-down")) {
			const tlScrollDown = gsap.timeline({
				scrollTrigger: {
					trigger: body,
					start: "top -50px",
				},
			});
			tlScrollDown.from(document.querySelector(".chevron-down"), {
				alpha: 0,
				y: 40,
				duration: 0.6,
				ease: "power2.out",
			});
		}
	
		document.querySelectorAll(".chevron-down").forEach((item) => {
			item.addEventListener("click", function (e) {
				e.preventDefault();
				gsap.to(window, {
					scrollTo: "#scrollDown",
					duration: 1.4,
					ease: "power3.inOut",
				});
			});
		});

	}
};
