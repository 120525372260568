import * as L from "leaflet";
import * as L1 from 'leaflet.markercluster';

// map page Client

export function initMapSingle() {
  const map_single = document.querySelector(".map-single");
  
  if(map_single){

    $.each($('.marker'), function () {
      
      var title  = jQuery(this).data("title");
      var lat    = jQuery(this).data("lat");
      var lng    = jQuery(this).data("lng");
      var url    = jQuery(this).data("url");
      var cat    = jQuery(this).data("cat");
      var CustomIcon   = new L.Icon({
        iconUrl: "/wp-content/themes/theme-vernalis/src/images/picto-localisation.png",
        iconSize: [30, 36],
      });

      var map = L.map(map_single).setView([lat, lng], 8);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Vernalis & &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(map);

      L.marker([lat, lng], { icon: CustomIcon }).addTo(map)
        
    });
  }
}


// map page all clients

export function initMap() {

  const mapi = document.querySelector(".map");

  var map    = L.map(mapi).setView([46.933, 1.533], 5.5);

  var carto  = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: 'Vernalis & &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  });

  map.addLayer(carto);

  var markers = [];

  $.each($('.marker'), function () {
      
    var title  = jQuery(this).data("title");
    var lat    = jQuery(this).data("lat");
    var lng    = jQuery(this).data("lng");
    var url    = jQuery(this).data("url");
    var cat    = jQuery(this).data("cat");
    var CustomIcon   = new L.Icon({
      iconUrl: "/wp-content/themes/theme-vernalis/src/images/picto-localisation.png",
      iconSize: [30, 36],
    });

    marker = [ title, lat, lng, url, CustomIcon, cat ];
    
    markers.push(marker);
    //console.log( markers );

    // for (var i = 0; i < markers.length; i++) {
    //   //var myIcon = new LeafIcon({iconUrl: markers[i][4] });
    //   marker = L.marker([markers[i][1],markers[i][2]], {icon: CustomIcon })
    //       .bindPopup('<div class="info-carto__title">' + markers[i][0]+'</div><div class="info-carto">'+ markers[i][5] +'</div><a class="info-carto__link" href="' + markers[i][3] + '">En savoir +</a>')
    //       .addTo(map);
    // }

    var markersCluster = new L1.MarkerClusterGroup({
      maxClusterRadius: 40,
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true
    });

    for (var i = 0; i < markers.length; i++) {
        var latLng = new L.LatLng(markers[i][1], markers[i][2]);
        var marker = new L.Marker(latLng, {icon: CustomIcon })
        .bindPopup('<div class="info-carto__title">' + markers[i][0]+'</div><div class="info-carto">'+ markers[i][5] +'</div><a class="info-carto__link" href="' + markers[i][3] + '">En savoir +</a>');
        markersCluster.addLayer(marker);
    }

    map.addLayer(markersCluster);

  });
  
}
