// Styles SCSS
import '../sass/app.scss';

// SVG polyfiil
import 'svgxuse';

// LazyLoad
import lazyLoadInit from './lazyload-init';
lazyLoadInit();

import Router from './utils/Router';
import common from './routes/common';
import home from './routes/home';
import page from './routes/page';
import single from './routes/single';

// Vanilla
document.addEventListener('DOMContentLoaded', function() {
	const routes = new Router({
		/** All pages */
		common,
		/** Home page */
		home,
		/** page */
		page,
		/** single */
		single
	});
	routes.loadEvents();
});
